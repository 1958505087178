
import {get, merge} from 'lodash';
import {hasWindow} from 'utils/functions';

// Define our expected dataLayer types
type VTypes = string | number;
type Values = VTypes | Record<string, VTypes>;
type Merged = Record<string, Values>;

// Define a global cache that lets us track our updates
let merged: Merged = {};

// Define global object on window
declare global {
	interface Window {
		dataLayer?: {
			push?: (obj: Merged) => void
		};
	}
}

export const dataLayer = {
	// Get allows us to read a value from a previous push
	get: (path: string, defaultValue: Values) : Values | undefined => (
		get<Merged, string, Values>(merged, path, defaultValue)
	),

	// Get pushes an update to the dataLayer and caches for future reads
	push: (obj: Merged) => {
		// Only push if we have a valid dataLayer
		if (
			hasWindow() &&
			window.dataLayer &&
			window.dataLayer.push
		) {
			window.dataLayer.push(obj);
		}

		// Always update our merged version of values
		merged = merge<Merged, Merged>(merged, obj);
	},
};
