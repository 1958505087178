
import {hasWindow} from 'utils/functions';

import {SLEvent, smartlook} from './smartlook';

// Define global object on window
declare global {
	interface Window {
		HubSpotConversations?: {
			on?: (event: string, cb: () => void) => void
			widget?: {
				refresh?(): void
			}
		}

		hsConversationsOnReady: [() => void]

		hbspt?: {
			forms?: {
				create(arg: unknown): void
			}
		}

		_hsq?: {
			push?(arg: unknown): void
		}
	}
}

export const hsChat = {
	init: () => {
		if (!hasWindow()) {
			return;
		}

		const onReady = () => {
			if (
				window.HubSpotConversations &&
				window.HubSpotConversations.on
			) {
				window.HubSpotConversations.on(
					'conversationStarted',
					() => {
						smartlook.track(SLEvent.ChatStarted);
					},
				);
			}
		};

		if (window.HubSpotConversations) {
			onReady();
		} else {
			window.hsConversationsOnReady = [onReady];
		}
	},

	refresh: () => {
		if (
			hasWindow() &&
			window.HubSpotConversations &&
			window.HubSpotConversations.widget &&
			window.HubSpotConversations.widget.refresh
		) {
			window.HubSpotConversations.widget.refresh();
		}
	},
};

const hsFormReady = (): boolean => Boolean(
	hasWindow() &&
	window.hbspt &&
	window.hbspt.forms &&
	process.env.GATSBY_HUBSPOT_ID
);

export const hsForm = {
	ready: hsFormReady,

	init: (formId: string, target: string) => {
		if (hsFormReady()) {
			window.hbspt?.forms?.create({
				region: 'na1',
				portalId: process.env.GATSBY_HUBSPOT_ID,
				formId,
				target: '#' + target,
				cssClass: '',
			});
		}
	}
};

let lastEmail = '';

export const hsTrack = {
	identify: (email: string) => {
		if (!email || email === lastEmail) {
			return;
		}

		if (
			hasWindow() &&
			window._hsq &&
			window._hsq.push
		) {
			window._hsq.push(['identify', {email}]);
			lastEmail = email;

			// On first identify, also track page view
			hsTrack.trackPageView();
		}
	},

	setPath: (path: string) => {
		if (
			hasWindow() &&
			window._hsq &&
			window._hsq.push
		) {
			window._hsq.push(['setPath', path]);
		}
	},

	trackPageView: () => {
		if (
			hasWindow() &&
			window._hsq &&
			window._hsq.push
		) {
			window._hsq.push(['trackPageView']);
		}
	},
};
