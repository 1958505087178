
import React, {FC} from 'react';
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';

import {links} from './links';

export interface IBrowserWrapper {
    children: React.ReactNode
}

export const BrowserWrapper: FC<IBrowserWrapper> = ({children}) => {
	const client = new ApolloClient({
		cache: new InMemoryCache(),

		defaultOptions: {
			query: {
				fetchPolicy: 'network-only',
			},
			watchQuery: {
				fetchPolicy: 'network-only',
			},
		},

		link: links(),
	});

	return (
		<ApolloProvider client={client}>
			{children}
		</ApolloProvider>
	);
};
