
import React, {useEffect} from 'react';
import {Script} from 'gatsby';
import {hsChat} from 'utils/window/hubspot';

const hsId = process.env.GATSBY_HUBSPOT_ID;
const hsProps = !hsId ? null : [
	{
		id: 'hs-script-loader',
		src: `//js-na1.hs-scripts.com/${hsId}.js`,
	},
	{
		src: '//js.hsforms.net/forms/embed/v2.js',
	},
];

export const HubSpot = () => {
	useEffect(() => {
		if (hsId) hsChat.init();
	}, []);

	return !hsId ? null : (
		<>
			{hsProps?.map((props, i) => (
				<Script key={i} {...props} />
			))}
		</>
	);
};
