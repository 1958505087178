
import React, {FC, ReactNode} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY || '');

interface StripeBoundaryProps {
	children: ReactNode;
}

export const StripeBoundary: FC<StripeBoundaryProps> = ({children}) => {
	const options: StripeElementsOptions = {
		fonts: [{
			cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'
		}]
	};

	return (
		<Elements stripe={stripePromise} options={options}>
			{children}
		</Elements>);
};
