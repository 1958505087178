import React from 'react';
import {GatsbyBrowser} from 'gatsby';

import {BrowserWrapper} from './src/providers/apollo/browser';
import {Scripts} from './src/providers/Scripts';
import {StripeBoundary} from './src/utils/stripe/boundary';
import {dataLayer} from './src/utils/window/dataLayer';
import {hsChat, hsTrack} from './src/utils/window/hubspot';

import 'bodyspec-shared-components/lib/index.css';
import './src/index.scss';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => {
	return (
		<BrowserWrapper>
			<StripeBoundary>
				{element}
			</StripeBoundary>
			<Scripts />
		</BrowserWrapper>
	);
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
	if (window.location) {
		dataLayer.push({
			originalLocation:
				window.location.protocol + '//' +
				window.location.hostname +
				window.location.pathname +
				window.location.search,
		});
	}
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({location, prevLocation}) => {
	hsChat.refresh();

	if (prevLocation !== null) {
		hsTrack.setPath(location.pathname);
		hsTrack.trackPageView();
	}
};
