import {ApolloLink, HttpLink} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {getToken} from 'utils/auth';
import {hasWindow} from 'utils/functions';
import {KV} from 'utils/types';

const authLink = () => (
	new ApolloLink((operation, forward) => {
		const token = getToken();
		if (token) {
			operation.setContext((context: {headers: KV}) => ({
				headers: {
					authorization: token,
					...context.headers,
				},
			}));
		}
		return hasWindow() ? forward(operation) : null;
	})
);

const errorLink = () => (
	onError(({graphQLErrors, networkError}) => {
		if (graphQLErrors) {
			graphQLErrors.map(({message, locations, path}) =>
				console.log([ //eslint-disable-line
					'[GraphQL error]:',
					`Message: ${message},`,
					`Location: ${locations},`,
					`Path: ${path}`,
				].join(' ')),
			);
		}

		if (networkError) {
			console.log(`[Network error]: ${networkError}`); //eslint-disable-line
		}
	})
);

const httpLink = () => (
	ApolloLink.split(
		(op) => Boolean(op.getContext().slowQuery),

		// slowQuery is set, use custom path
		ApolloLink.split(
			(op) => op.getContext().slowQuery === 'super-slow',
			new HttpLink({uri: '/graphqz'}),
			new HttpLink({uri: '/graphqs'}),
		),

		// slowQuery is not set, use standard path
		new HttpLink({uri: '/graphql'}),
	)
);

export const links = () => (
	ApolloLink.from([
		errorLink(),
		authLink(),
		httpLink(),
	])
);
