import React from 'react';
import {Script} from 'gatsby';

const impactId = process.env.GATSBY_IMPACT_ID;
export const impactScript = !impactId ? '' : `
if (typeof window !== "undefined") {
	(function(a, b, c, d, e, f, g) {
		e['ire_o'] = c;
		e[c] = e[c] || function() {
			(e[c].a = e[c].a || []).push(arguments)
		};
		f = d.createElement(b);
		g = d.getElementsByTagName(b)[0];
		f.async = 1;
		f.src = a;
		g.parentNode.insertBefore(f, g);
	})('https://utt.impactcdn.com/${impactId}.js', 'script', 'ire', document, window);
}`;

export const Impact = () => {
	return <>{impactScript && <Script>{impactScript}</Script>}</>;
};
