
import React from 'react';

import {HubSpot} from './_HubSpot';
import {Impact} from './_Impact';
import {Smartlook} from './_Smartlook';
import {TikTok} from './_TikTok';
import {Twitter} from './_Twitter';

export const Scripts = () => {
	return (
		<>
			<HubSpot />
			<Impact />
			<Smartlook />
			<TikTok />
			<Twitter />
		</>
	);
};
